.email-info-box {
  margin: 60px auto;
  box-sizing: border-box;
}

.confirm-email-content-box {
  display: flex;
  flex-direction: column;
  margin: 60px auto;
  width: 75%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 12px 24px -6px rgba(9, 30, 66, 0.25);
}

div.confirm-email-content-title {
  margin: 40px;
  margin-bottom: 0px;
  color: #071631;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

div.confirm-email-content-subtitle {
  margin: 30px 40px;
  color: #071631;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

input.email {
  display: block;
  margin-left: 40px;
  box-sizing: border-box;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;
  padding: 10px 10px;
  line-height: 100%;
  font-family: "Barlow";
  font-size: 16px;
}

@media (max-width: 767px) {
  .email-info-box {
    margin: 10px auto;
    box-sizing: border-box;
  }

  .confirm-email-content-box {
    display: flex;
    flex-direction: column;
    margin: 30px auto;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
      0 12px 24px -6px rgba(9, 30, 66, 0.25);
  }

  div.confirm-email-content-title {
    margin: 20px;
    margin-bottom: 0;
    width: 90%;
    color: #071631;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
  }

  div.confirm-email-content-subtitle {
    margin: 20px;
    width: 90%;
    color: #071631;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }


}
