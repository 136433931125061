.order-summary {
    position: sticky;
    margin:10px;
    margin-top: 30px;
    min-width: 300px;
}


.order-summary > div {
  border-radius: 4px;
  background-color: #ffffff;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 20px 32px -8px rgba(9, 30, 66, 0.25);
}

.order-summary-title {
  color: #172b4d;
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid#f4f5f7;
}

.empty-carts {
  color: #172b4d;
  font-family: 'Barlow Condensed';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid#f4f5f7;
}

.recipients {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 26px;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.total {
  margin-top: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 2px solid#f4f5f7;
  height: 20px;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.total-amt {
  color: #172b4d;
  font-family: Lato;
  font-size: 29px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
}

.btns {
  margin: 20px 0;
  grid-area: btns;
}

button.checkout {
  padding: 10px 25%;
  margin: auto;
  border-radius: 3px;
  background-color: #071631;
  cursor: pointer;
  color: #e5bb34;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  outline: none;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.checkout:disabled {
  background-color: rgba(9, 30, 66, 0.04);
  color: #a5adba;
  cursor: auto;
}

.checkout-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.in-cart-items-subtitle {
  margin: 20px;
  height: 20px;
  width: 105px;
  color: #172b4d;
  font-family: 'Barlow Condensed';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: uppercase;
}

.in-cart-items-line-item-top-line {
  margin: 20px 20px 0;
  display: flex;
  justify-content: space-between;
}

.in-cart-items-line-item-bottom-line {
  margin-top: 5px;
  margin-left: 20px;
  color: #6d7278;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.line-item-label {
  height: 20px;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.line-item-value {
  height: 17px;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

.in-cart-items {
  border-bottom: 2px solid#f4f5f7;
  padding-bottom: 20px;
}


@media only screen and (max-width: 769px) {
  .order-summary {
    margin: 10px;
  }

  button.checkout {
    padding: 10px 25%;
    border-radius: 3px;
    margin: auto;
    background-color: #071631;
    cursor: pointer;
    color: #e5bb34;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    outline: none;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
