.pekelah-container {
  height: 471px;
}

.pekelah {
  z-index: 10;
  width: 350px;
  height: 441px;
  margin: 0;
  border: 1px solid #e5bb34;
  background-color: #071631;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 20px 32px -8px rgba(9, 30, 66, 0.25);
}

.pekelah1 {
  width: 350px;
  height: 441px;
  margin: 0;
  border: 1px solid #e5bb34;
  background-color: #071631;
  box-shadow: 0 0 1px 0 rgb(9 30 66 / 31%), 0 20px 32px -8px rgb(9 30 66 / 25%);
}

.pekelah-top {
  width: 350px;
  height: 50px;
  background: url(../../images/lattice.svg);
}

.img-rectangle {
  margin-top: 25px;
  background-color: white;
  width: 348px;
  height: 163px;
}

.img-rectangle img {
  display: flex;
  margin: auto;
  padding: 10px 0;
  height: 163px;
}

.pekelah-bottom {
  padding-left: 30px;
  width: 100%;
}

div.price-tag {
  position: absolute;
  margin-top: -15px;
  z-index: 100;
  height: fit-content;
  width: 340px;
  color: #071631;
  font-family: 'Barlow Condensed';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

div.zoom-in {
  cursor: pointer !important;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  padding: 7px;
  z-index: 100;
  height: 33px;
  width: 33px;
  color: #071631;
  border-radius: 50%;
  background-color: #e5bb34;
}
div.zoom-out-container {
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.zoom-out {
  padding: 7px;
  z-index: 100;
  height: fit-content;
  width: fit-content;
  color: #e5bb34;
  border-radius: 50%;
  background-color: #071631;
}
div.back-to-order {
  width: max-content;
  color: #071631;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  background-color: white;
  padding: 5px;
  border-radius: 0 3px 3px 0;
  opacity: 0.9;
}

.back-img-container {
  background: white;
  height: 389px;
  width: 348px;
}

.back-img-div {
  display: 'flex';
  margin: 0px auto;
  height: 95%;
  background-color: white;
  width: 346px;
}

.back-img {
  display: block;
  margin: auto;
  height: 100%;
  width: 300px;
  padding: 10px 10px;
  object-fit: cover;
}

div.price-tag span {
  background-color: #e5bb34 !important;
  float: right;
  padding: 2px 5px;
}

.pekelah-title {
  margin-top: 25px;
  height: 24px;
  color: #e9e5d8;
  font-family: 'Barlow Condensed';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
}

.pekelah-subtitle {
  margin-top: 10px;
  margin-right: 10px;
  height: 40px;
  /* background-color: white; */
  width: 300px;
  color: #e9e5d8;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 15px;
}

.pekelah-divider {
  margin-top: 10px;
  height: 1px;
  width: 280px;
  background-color: #e5bb34;
}

.quantity-label {
  margin-top: 10px;
  height: 17px;
  color: #e9e5d8;
  font-family: 'Barlow Condensed';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
}

.quantity-btns {
  margin-top: 10px;
  display: inline-flex;
  justify-content: space-between;
}

.quantity-btn {
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: #e5bb34;
  font-family: 'Barlow Condensed';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #e5bb34;
  margin-right: 5px;
  outline: none;
}

.btn-pressed {
  background-color: #e5bb34;
  color: #071631;
}

.other-quantity-btn {
  width: fit-content;
  height: 40px;
  cursor: pointer;
  color: #e5bb34;
  font-family: 'Barlow Condensed';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #e5bb34;
  margin-right: 5px;
  outline: none;
}

.ui.pink.circular.empty.label.color-circle,
.ui.orange.circular.empty.label.color-circle,
.ui.blue.circular.empty.label.color-circle,
.ui.green.circular.empty.label.color-circle {
  margin-left: 5px;
}

div.pekelach-added {
  margin: 0 0 50px;
  height: 31px;
  width: 350px;
  border-radius: 0 0 10px 10px;
  background-color: #e5bb34;
  display: flex;
  justify-content: center;
}

div.number-added {
  padding-top: 5px;
  text-transform: uppercase;
  height: 17px;
  color: #071631;
  font-family: 'Barlow Condensed';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

.remove-btn {
  cursor: pointer;
  margin-top: 5px;
  margin-left: 10px;
  display: block;
  box-sizing: border-box;
  height: 20px;
  width: fit-content;
  border: 1px solid #071631;
  border-radius: 2px;
  background-color: #071631;
  color: #e5bb34;
  font-family: 'Barlow Condensed';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  display: flex;
  text-transform: uppercase;
  text-align: center;
}

.other-quantity {
  margin-top: 10px;
  height: 40px;
  width: 78px;
  border-radius: 3px;
  background-color: #f4f5f7;
  outline: none;
  padding-left: 10px;
  color: #172b4d;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.kicks {
  height: 471px;
  width: 350px;
}

.kicks > div {
  position: absolute;
}

@media only screen and (max-width: 767px) and (min-device-width: 315px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pekelah,
  .pekelah1 {
    width: 320px;
  }

  .kicks {
    width: 320px;
  }

  .back-img-div {
    width: 310px;
  }

  .back-img-container {
    width: 318px;
  }

  .img-rectangle {
    margin-top: 25px;
    background-color: white;
    width: 318px;
    height: 163px;
  }

  div.pekelach-added {
    width: 320px;
  }

  .pekelah-bottom {
    padding-left: 4%;
  }

  div.price-tag {
    width: 320px;
  }

  .pekelah-top {
    width: 320px;
  }

  .quantity-btn {
    cursor: pointer;
    height: 35px;
    width: 35px;
    font-size: 22px;
    line-height: 29px;
    margin-right: 0px;
    display: inline-flex;
    justify-content: center;
    box-sizing: border-box;
  }

  .other-quantity-btn {
    cursor: pointer;
    width: 20%;
    height: 35px;
    font-size: 22px;
    line-height: 29px;
    margin-right: 0px;
    display: inline-flex;
    justify-content: center;
    box-sizing: border-box;
  }

  .quantity-btns {
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
  }

  .number-buttons {
    display: inline-flex;
    justify-content: space-evenly;
    width: 75%;
  }
}
