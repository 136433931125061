.ui.table.people-table {
  width: 600px;
  margin-left: 10px;
}

div.people-table {
  margin-top: 20px;
  padding: 10px;
  background-color: #ffffff;
}

.ui.table {
  white-space: normal !important;
}

@media (max-width: 650px) {
  .ui.table.people-table {
    width: 95%;
    margin-left: 10px;
  }
}
