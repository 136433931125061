.pekalah-cart-line-item {
  box-sizing: border-box;
  height: 89px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.pekalah-cart-line-item:first-child {
  margin-top: 20px;
}
.pekalah-cart-line-item:last-child {
  margin-bottom: 30px;
}

.pekalah-img {
  display: block;
  width: 59px;
  height: 51px;
  margin: 18px 0 19px 10px;
}

.pekalah-cart-item-text {
  height: 17px;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 36px;
}

.pekalah-cart-item-text.item-desc {
  width: fit-content;
  margin-left: 20px;
}

.pekalah-cart-item-text.quantity {
  margin-left: 30px;
}

.total-price {
  margin-top: 35px;
  margin-right: 15px;
  height: 24px;
  width: 63px;
  color: #071631;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.img-with-text {
  width: 250px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.remove-link {
  margin-top: 30px;
  height: 32px;
  color: #bf2600;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  cursor: pointer;
}


@media only screen and (max-width: 1024px) {
  .pekalah-cart-line-item {
    box-sizing: border-box;
    height: 89px;
    border: 1px solid #f5f5f5;
    background-color: #ffffff;
    margin-left: 10px;
    margin-right: 0px;
    margin-top: 10px;
    display: inline-table;
    justify-content: space-around;
  }

  .pekalah-cart-item-text.quantity {
    margin-left: 10px;
  }

  .pekalah-cart-item-text.price-desc {
    float: right;
    margin-right: 10px;
    margin-top: 0px;
  }

  .remove-link {
    width: 0px;
    float: right;
  }

  .total-price {
    margin-top: 15px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
