.ezheader-container {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.ezheader {
  height: 80px;
  width: 100%;
  background-color: #071631;
  display: flex;
  align-items: center;
}

.ezheader-divider {
  height: 4px;
  width: 100%;
  background-color: #e5bb34;
}

.logo-img {
  cursor: pointer;
  margin-left: 15px;
}

.logo-txt {
  cursor: pointer;
  margin-left: 15px;
  height: 48px;
  width: 147px;
  color: #ffffff;
  font-family: Barlow;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 48px;
}

.logo-txt-prefix {
  font-family: Barlow Condensed;
  font-weight: 500;
}

.right-hand-side,
.right-hand-side-mobile {
  cursor: pointer;
  color: #e9e5d8;
  font-family: 'Barlow Condensed';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 26px;
  margin-right: 20px;
  margin-left: auto;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.right-hand-side div,
.right-hand-side-mobile div {
  margin-left: 20px;
  cursor: pointer;
}

.subheader {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 12px 24px -6px rgba(9, 30, 66, 0.25);
}

.subheader-title {
  margin: 20px 0px 5px 50px;
  color: #071631;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 29px;
  text-shadow: 1px -1px 0 #e5bb34, -1px 1px 0 #e5bb34, 1px 1px 0 #e5bb34;
}

.subheader-subtitle {
  margin-left: 50px;
  margin-bottom: 5px;
  color: #071631;
  font-size: 18px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 24px;
}

.pickup-msg {
  margin: 10px 30px 20px 50px;
  height: 38px;
  color: #071631;
  font-family: Barlow;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 19px;
}

#hamnav {
  width: 100%;
  top: 0;
}

#hamnav label,
#hamburger {
  display: none;
}

.right-hand-side-mobile {
  display: none;
}

.mobile-area,
.mobile-landing-header {
  display: none;
}

.hurry-hurry {
  height: 40px;
  width: 100%;
  background-color: #e5bb34;
  color: #071631;
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 8px 0;
}

.hurry-hurry span {
  font-weight: bold;
}

@media (max-width: 767px) {
  .ezheader {
    height: 50px;
  }

  .logo-img {
    width: 30px;
    margin-left: 5px;
  }

  .logo-txt {
    font-size: 28px;
    margin-left: 5px;
  }

  .hurry-hurry {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .subheader-title {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 20px;
  }

  .subheader-subtitle {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 16px;
  }

  .pickup-msg {
    margin-left: 10px;
    margin-right: 20px;
    font-size: 12px;
    width: auto;
    margin-bottom: 50px;
  }

  #hamnav label {
    display: inline-block;
    color: #e9e5d8;
    font-style: normal;
    font-size: 1.3em;
    font-weight: 500;
    padding: 10px;
    float: right;
    margin-left: auto;
  }

  .right-hand-side-mobile div {
    width: 100%;
    border-top: 1px solid #071631;
    background: #071631;
    padding: 10px;
    font-size: 20px;
  }

  .right-hand-side {
    display: none;
  }

  .mobile-area {
    display: flex;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 4px;
  }

  .mobile-landing-header {
    display: flex;
    margin-left: auto;
  }

  .right-hand-side-mobile {
    display: none;
    position: absolute;
    top: 50px;
    right: 0px;
    z-index: 99;
  }
  #hamnav input:checked ~ .right-hand-side-mobile {
    display: block;
  }
}
