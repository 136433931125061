.toggle-and-remove-cell {
  display: block;
}

.remove-link.link-action {
  margin-top: -3px;
  height: 32px;
  width: 50px;
  color: #bf2600;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  float: right;
}

.remove-link.link-action:hover,
.remove-link.link-action:active,
.remove-link.link-action:focus {
  outline: none;
}

td > .line-item-name {
  text-align: left;
  height: 17px;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

td > .address {
  text-align: left;
  font: normal normal normal 14px/16px Lato;
  letter-spacing: 0px;
  color: #737373;
}

.personal-greeting-title {
  margin-top: 12px;
  height: 17px;
  width: 114px;
  color: #6b778c;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

.personal-greeting-msg {
  margin-top: 10px;
}

.greeting-switch {
  margin-right: 10px;
}

.default-greet {
  display:inline;
}

@media only screen and (max-width: 769px) {
  .toggle-and-remove-cell {
    display: block;
  }

  .greeting-switch {
    margin-right: 10px;
    margin-top: 5px;
  }

  td > .line-item-name {
    word-break: break-word;
  }

}
