.cart-default-greeting {
  max-width: 430px;
  min-width: 270px;
  width: 50%;
  height: auto;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin: 20px 20px 20px 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.cart-default-greeting-title {
  height: 24px;
  color: #172b4d;
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 30px;
}

.cart-default-greeting-text {
  margin-top: 5px;
  margin-bottom: 20px;
  height: auto;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 20px;
}

#greetingText {
  margin-right: 5px;
  height: auto;
  color: #172b4d;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

#editGreetingBtn {
  margin-top: 15px;
}

@media only screen and (max-width: 769px) {
  .cart-default-greeting {
    margin: 10px;
    width: auto;
  }
}
