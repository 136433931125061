.main-app-content {
  display: flex !important;
}

.login-page {
  margin: 0;
  width: 100%;
  background: url('../../images/landing/bg1.svg');
}

.login-form {
  display: flex;
  flex-direction: column;
  margin: 7% auto;
  width: 50%;
  max-width: 400px;
  border-radius: 3px;
  background-color: #ffffff !important;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 12px 24px -6px rgba(9, 30, 66, 0.25);
}

.sign-in-title {
  margin: 40px;
  margin-bottom: 0px;
  color: #071631;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

.sign-in-subtitle {
  margin: 10px 40px;
  color: #071631;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.email-pass-container {
  margin-left: 40px;
  margin-top: 20px;
  width: 75%;
}

.login-in-btn {
  font-family: Barlow;
  display: block;
  margin: 40px auto 10px;
  padding: 10px 50px;
  border-radius: 3px;
  background-color: #071631;
  color: #e5bb34;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  border: 0;
  cursor: pointer;
}

.login-in-btn:hover {
  cursor: pointer;
  background-color: #e5bb34;
  color: #071631;
}

.login-in-btn:disabled {
  background-color: #f7f7f7;
  color: #d8d8d8;
  cursor: auto;
}

.forgot-password-link {
  margin-bottom: 40px;
  padding: 10px;
  text-align: center;
  color: #0052cc;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.ui.negative.message.login-error {
  margin-top: 10px;
  height: fit-content;
  width: 290px;
}

.forgot-password-link {
  margin-bottom: 2em;
  cursor: pointer;
}

@media (max-width: 767px) {
  .login-page {
    box-sizing: border-box;
  }

  .login-form {
    margin: 3em auto;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff !important;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
      0 12px 24px -6px rgba(9, 30, 66, 0.25);
  }

  .sign-in-title {
    margin: 20px;
    margin-bottom: 0px;
    width: 90%;
    color: #071631;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
  }

  .sign-in-subtitle {
    margin: 10px 20px;
    width: 90%;
    color: #071631;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .email-pass-container {
    margin-left: 20px;
    margin-top: 20px;
    width: 80%;
  }

  .ui.negative.message.login-error {
    width: 280px;
  }
}
