input.ezinput {
  display: block;
  padding-left: 5px;
  margin-top: 5px;
  display: block;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;
  font-size: 16px;
  width: 100%;
  font-family: "Barlow";
}

input.ezinput.error {
  border: 2px solid #de350b;
  outline: 0;
}

input.ezinput:disabled {
  border: none;
  background: #f4f5f7;
}

.help-text {
  margin-top: 7px;
  margin-left: 5px;
  height: 17px;
  width: 229px;
  color: #6b778c;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.error-message-div img {
  height: 16px;
}
