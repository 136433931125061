.cart-btn {
  cursor: pointer;
  margin: auto;
  padding: 10px;
  border: 1px solid #e5bb34;
  border-radius: 0.25rem;
  background-color: #e5bb34;
  outline: none;
  text-transform: uppercase;
  color: #071631;
  font-family: 'Barlow Condensed';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  display: flex;
  align-items: center;
  transition: color .15s;
}

.cart-btn:hover{
  background-color: #071631;
  color: #e5bb34;
  border: 1px solid #e5bb34;
}

.cart-btn .quantity {
  font-weight: 400;
}

@media(max-width:767px) {
.cart-text {
  display: none;
}

i.icon.cart-icon {
  padding: 0px;
  size:'mini';
}

.cart-btn {
  margin: auto;
  padding: 5px;
  font-size: 20px;
}
}
