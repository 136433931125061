.help-modal {
  font-size: 18px;
  line-height: 25px;
  color: #071631;
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
}

.help-modal.ui.modal .ui.header {
  width: 100%;
  text-align: center;
  display: flex;
  height: 3em;
  align-items: center;
}

.help-modal.ui.modal .header .content,
.help-modal.ui.modal .header .icon {
  padding-bottom: 25px;
}

.help-modal.ui.modal .header .content {
  margin-top: 10px;
}

.help-type-btn-container {
  margin-top: 20px;
  margin-bottom: 16px;
}

.help-type-btn-container .ui.active.button {
  border-radius: 3px;
  background-color: #42526e;
  color: #ffffff;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

.help-type-btn-container .ui.button {
  border-radius: 3px;
  background-color: rgba(9, 30, 66, 0.04);
  color: #42526e;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

.bold-label {
  color: #6b778c !important;
  font-family: Barlow;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
}

.help-text {
  color: #172b4d;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  width: 100%;
}

.call-us-help-text {
  margin-left: 5px;
  color: #172b4d;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.bottom-help-text {
  margin-top: 10px;
}

@media only screen and (max-width: 769px) {
  .help-text {
    margin-bottom: 2em;
  }
}
