.highlight {
  background: yellow;
  transition: opacity 3s;
  -webkit-animation: 3s ease 0s normal forwards 1 fadein;
  animation: 3s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0.2;
  }
  66% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0.2;
  }
  66% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.selected {
  background: #76767614 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e3;
}

.paid-for {
  background-color: #f7f7f7;
}

.record-table-row {
  width: max-content;
}

.name-inside-cell {
  text-align: left;
  font: normal normal bold 16px/24px Lato;
  letter-spacing: 0px;
  color: #4a4a4a;
}

.paid-for .name-inside-cell {
  color: #7d8693;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.address-inside-cell {
  text-align: left;
  font: normal normal normal 14px/16px Lato;
  letter-spacing: 0px;
  color: #737373;
}

.paid-for .address-inside-cell {
  color: #7d8693;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.add-remove-customer {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.btn-remove-from-cart {
  font-size: 13px !important;
}

.categories {
  margin-left: auto;
}

.btn-add-to-cart {
  font-size: 14px !important;
}

.btn-remove-from-cart,
.btn-add-to-cart {
  text-align: center !important;
  font: normal normal normal 11px/13px Lato;
  letter-spacing: 0px;
  margin-top: 5px !important;
}

.name-address-content {
  margin-left: 10px;
  width: max-content;
  height: fit-content;
}

.green-check {
  margin-top: 10px;
  margin-left: auto;
}

.green-check-icon {
  color: #21ba45;
}

.paid-for-icon-section {
  margin-left: auto;
  box-sizing: border-box;
  height: auto;
  border: 1px solid #f5f5f5;
  background-color: #f7f7f7;
}

.paid-for-icon {
  height: 16px;
  width: 16px;
}

.ordered-and-paid {
  background-color: #7d8693;
  color: #e9e5d8;
  font-family: Lato;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.33px;
  line-height: 11px;
  text-transform: uppercase;
  padding: 5px;
  height: fit-content;
  width: 60px;
  margin-left: -5px;
  margin-top: 5px;
}
