.breadcrumb {
  list-style: none;
  overflow: hidden;
  padding-left: 0;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 19px;
}
.breadcrumb li {
  float: left;
}

.breadcrumb li a {
  color: #071631;
  text-decoration: none;
  padding: 10px 0 10px 45px;
  background: #e5bb34;
  position: relative;
  display: block;
  float: left;
}

.breadcrumb li:nth-child(1) a {
  width: max-content;
  text-align: left;
  padding-left: 10px;
}

.breadcrumb li a::after {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid #e5bb34;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumb li a::before {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 5px;
  left: 100%;
  z-index: 1;
}

/* PEKELACH PAGE */
.pekelach-page .breadcrumb li:first-child a {
  padding-left: 10px;
}
.pekelach-page .breadcrumb li:nth-child(2) a {
  background: #e9e5d8;
}
.pekelach-page .breadcrumb li:nth-child(2) a:after {
  border-left-color: #e9e5d8;
}
.pekelach-page .breadcrumb li:nth-child(3) a {
  background: #e9e5d8;
  padding-right: 10px;
  border: 0;
}
.pekelach-page .breadcrumb li:nth-child(3) a::after {
  border-left: 30px solid #f4f5f7;
  cursor: auto;
}
.pekelach-page .breadcrumb li:nth-child(3) a::before {
  border-left: 30px solid #f4f5f7;
  cursor: auto;
}

/* SHALOCH MANOS */
.shaloch-manos-navigation-bar .breadcrumb li:first-child a {
  padding-left: 10px;
  color: #e9e5d8;
  background-color: #7d8693;
}
.shaloch-manos-navigation-bar .breadcrumb li:first-child a:after {
  border-left-color: #7d8693;
}
.shaloch-manos-navigation-bar .breadcrumb li:nth-child(2) a {
  background: #e5bb34;
}
.shaloch-manos-navigation-bar .breadcrumb li:nth-child(2) a:after {
  border-left-color: #e5bb34;
}
.shaloch-manos-navigation-bar .breadcrumb li:nth-child(3) a {
  background: #e9e5d8;
  padding-right: 15px;
}
.shaloch-manos-navigation-bar .breadcrumb li:last-child a::after {
  border: 0;
}
