.forgot-password-modal {
  font-size: 18px;
  line-height: 25px;
  color: #071631;
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
}

.forgot-password-modal.ui.modal .ui.header {
  width: 100%;
  text-align: center;
  display: flex;
  height:auto;
}

.forgot-password-modal.ui.modal .header .content,
.forgot-password-modal.ui.modal .header .icon {
  margin-top: -10px;
}
