.search-customer-box {
  box-sizing: border-box;
  width: 75%;
  max-width: 576px;
  margin: 60px auto;
}

.search-customer-content-box {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 12px 24px -6px rgba(9, 30, 66, 0.25);
}

.search-customer-content-title {
  margin: 40px;
  margin-bottom: 0;
  color: #071631;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

.search-customer-content-box .important-message {
  margin: 30px 40px;
  color: #071631;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 20px;
}

.search-customer-content-box .action-message {
  margin: 30px 40px;
  color: #071631;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.name-address-container {
  margin-left: 40px;
  width: 80%;
}

label.email {
  margin-left: 40px;
  height: 17px;
  color: #7D8693;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

input.email {
  display: block;
  margin-left: 40px;
  margin-top: 5px;
  box-sizing: border-box;
  width: 50%;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;
  padding: 10px 10px;
  line-height: 100%;
}

.email-help-text {
  margin-top: 6px;
  margin-left: 40px;
  width: 50%;
  color: #071631;
  font-size: 14px;
  letter-spacing: -0.09px;
  line-height: 16px;
}

button.email {
  font-family: Barlow;
  display: block;
  margin: 40px auto 40px;
  padding: 10px;
  border-radius: 3px;
  background-color: #071631;
  color: #e5bb34;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  border: 0;
}

.did-not-find-div {
  margin: 30px auto;
  width: max-content;
}

button.email:hover {
  cursor: pointer;
  background-color: #e5bb34;
  color: #071631;

}

button.email:disabled {
  background-color: #f7f7f7;
  color: #d8d8d8;
  cursor: auto;
}



@media(max-width:767px){
  label.email {
    margin-left: 20px;
    height: 17px;
    color: #7D8693;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }

  input.email {
    display: block;
    margin-left: 20px;
    box-sizing: border-box;
    width: 80%;
    border: 2px solid #dfe1e5;
    border-radius: 3px;
    background-color: #fafbfc;
    padding: 10px 10px;
    line-height: 100%;
  }

  .email-help-text {
    margin-top: 6px;
    margin-left: 20px;
    width: 80%;
    color: #071631;
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 19px;
  }

  .search-customer-box {
    margin: 10px auto;
    box-sizing: border-box;
    width: auto;
  }

  .search-customer-content-box {
    display: flex;
    flex-direction: column;
    margin: 30px auto;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
      0 12px 24px -6px rgba(9, 30, 66, 0.25);
  }

  .search-customer-content-title {
    margin: 20px;
    margin-bottom: 0;
    color: #071631;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
  }

  .search-customer-content-box .important-message {
    margin: 15px 30px 20px 20px;
    color: #071631;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .search-customer-content-box .action-message {
    margin: 15px 30px 20px 20px;
    color: #071631;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .name-address-container {
    margin-left: 20px;
    width: 90%;
  }

}
