.header-landing-page {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-left: auto;
}

.register-btn {
  border-radius: 0.25rem;
  background-color: #e5bb34;
  color: #071631;
  font-family: 'Barlow Condensed';
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  padding: 6px;
  border: 1px solid #e5bb34;
  transition: color 0.15s;
}

.register-btn:hover {
  background-color: #071631;
  color: #e5bb34;
  border: 1px solid #e5bb34;
}

.header-login {
  height: 40px;
  width: 66px;
  color: #e9e5d8;
  font-family: 'Barlow Condensed';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  padding: 4px 10px;
  border: 1px solid #071631;
  transition: color 0.15s;
  text-transform: uppercase;
}

.header-login:hover {
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
  line-height: 26px;
}

@media (max-width: 767px) {
  .register-btn {
    padding: 4px;
    font-size: 20px;
  }

  .header-login {
    margin-left: 5px !important;
  }

  .header-landing-page {
    margin-right: 0px;
  }
}
