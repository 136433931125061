.verify-pin-box {
  box-sizing: border-box;
  width: 75%;
  max-width: 576px;
  margin: 60px auto;
}

.verify-pin-content-box {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 12px 24px -6px rgba(9, 30, 66, 0.25);
}

div.verify-pin-content-title {
  margin: 40px;
  margin-bottom: 0;
  color: #071631;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

div.verify-pin-content-subtitle {
  margin: 30px 40px;
  color: #071631;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}



.confirmation-code-container {
  margin-left: 40px;
  width: 33%;
}

.password-container {
  margin-top: 40px;
  display: flex;
  margin-left: 40px;
  gap: 20px;
}

.password-field {
}

.link-action {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #5383ec;
  cursor: pointer;
  text-align: start;
  outline: none;
  font-size: 16px;
  font-family: 'Barlow', sans-serif;
}

.link-action:hover{
  color: #0056b3;
  text-decoration: underline;
}

input + .link-action {
  margin-top: 8px;
}

button.register-account {
  font-family: Barlow;
  display: block;
  margin: 60px auto 40px;
  width: 174px;
  border-radius: 3px;
  background-color: #071631;
  height: 40px;
  color: #e5bb34;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  border: 0;
}

button.register-account:hover {
  cursor: pointer;
  background-color: #e5bb34;
  color: #071631;
}

button.register-account:disabled {
  background-color: #f7f7f7;
  color: #d8d8d8;
  cursor: not-allowed;
}

.error-message-div {
  display: flex;
  margin-top: 5px;
  margin-left: 0px;
  margin-bottom: 10px;
}

.error-message-div > img {
  margin-right: 2px;
  width: 16px;
}

.error-message {
  color: #de350b;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 16px;
  width: 18em;
}

@media(max-width:767px) {
  .verify-pin-box {
    margin: 10px auto;
    box-sizing: border-box;
    width: auto;
  }

  .verify-pin-content-box {
    display: flex;
    flex-direction: column;
    margin: 30px auto;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
      0 12px 24px -6px rgba(9, 30, 66, 0.25);
  }

  div.verify-pin-content-title {
    margin: 20px;
    margin-bottom: 0;
    color: #071631;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
  }

  div.verify-pin-content-subtitle {
    margin: 15px 30px 20px 20px;
    color: #071631;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .confirmation-code-container {
    margin-left: 20px;
    width: 80%;
  }

  .password-container {
    margin-top: 5px;
    display: flow-root;
    margin-left: 20px;
    width: 80%;
  }

  .password-field {
    margin-top: 15px;
  }

}
