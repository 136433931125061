#greeting-suffixes {
  margin-top: 30px;
}

label.greeting-suffix-radio input[type='radio'] {
  vertical-align: baseline;
  margin-right: 5px;
  margin-bottom: 15px;
}

.label-greeting-suffix {
  font-size: 16px;
}
