div.checkbox-generator {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 15px;
}

.ui.checkbox.staff-category-checkbox {
  margin-top: 10px;
}

.checkbox-generator h5 {
  margin-bottom: 0;
  margin-top: 15px;
}
