.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  text-align: start;
  color: #0052cc;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  outline: none;
  font-family: "Barlow";
}
