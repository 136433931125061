label.code {
  height: 17px;
  color: #7D8693;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.ui.selection.dropdown {
  display: block;
  padding-left: 5px;
  margin-top: 5px;
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 229px !important;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;
}

.ui.selection.dropdown div.visible.menu.transition div.item.active,
.ui.selection.dropdown div.visible.menu.transition div.item:hover {
  background: rgb(216, 210, 210);
}

.ui.selection.dropdown input.search {
  margin-left: -10px;
}
