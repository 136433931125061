.pekelah-cart {
  margin-left: 15px;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  background-color: white !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white !important;
}

.cart-title {
  margin-left: 30px;
  margin-top: 30px;
  width: fit-content;
  margin-bottom: 20px;
  color: #172b4d;
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

.more-pekelach {
  cursor: pointer;
  margin-left: 27px;
  margin-top: 30px;
}

.more-pekelach span {
  height: 32px;
  width: 120px;
  color: #42526e;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
}

.margin-around-msg {
  margin: 10px 30px 20px 30px !important;
}

@media only screen and (max-width: 769px) {
  div.cart-title {
    margin-bottom: 5px;
  }

  .pekelah-cart {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: white !important;
  }

  .more-pekelach {
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
  }

  .margin-around-msg {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
