.ui.modal > .header {
  width: 100%;
  height: auto;
  padding: 10px;
}

@media only screen and (max-width: 767px) {
  .ui.modal > .header {
    width: 100%;
    height: auto;
    padding: 10px;
  }
}
