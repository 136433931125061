.floaty-error-message {
  z-index: 10;
  position: absolute;
  right: 0;
  top: 0;
  padding: 25px;
  height: 172px;
  width: 320px;
  border-radius: 3px;
  background-color: #de350b;
  display: flex;
  border: 5px solid white;
}

.error-img {
  height: 24px;
  width: 24px;
}

.error-msg-title {
  height: 24px;
  width: 220px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.error-msg-content {
  margin-top: 20px;
  height: 24px;
  width: 220px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.error-content-box {
  margin-left: 10px;
}
