.shaloch-manos-cart {
  margin-left: 15px;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  background-color: white !important;
  display: flex;
  flex-direction: column;
  background-color: white !important;
}

.cart-title {
  margin-left: 30px;
  margin-top: 30px;
  width: fit-content;
  margin-bottom: 20px;
  color: #172b4d;
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

.shaloch-manos-cart-content {
}

.top-cards {
  display: flex;
}

.show-address-btn {
  margin-left: 20px;
  height: 24px;
  width: 101px;
  color: #42526e;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.more-recipients {
  cursor: pointer;
  margin-left: 27px;
  margin-top: 30px;
}

.more-recipients span {
  height: 32px;
  width: 120px;
  color: #42526e;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
}


@media only screen and (max-width: 769px) {

  .shaloch-manos-cart {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .cart-title {
    margin-left: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
  }

  .more-recipients {
    margin-left: 10px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
.top-cards {
  flex-wrap: wrap;
}
}
