.checkout-page {
  font-family: 'Lato';
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 2fr 2fr;
  gap: 10em;
  width:100%;
}

.checkout-main-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.checkout-title {
  margin-top: 30px;
  margin-left: 30px;
  height: 28px;
  color: #172b4d;
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;

}

@media only screen and (max-width: 1100px) {
  .checkout-page {
      gap:3em;
  }
}

@media only screen and (max-width: 950px) {
  .checkout-page {
      display: block;
  }
}
