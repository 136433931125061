div.line-items-table-container {
  margin-left: 30px;
  margin-right: 40px;
  margin-bottom: 30px;
}

.header {
  height: 17px;
  width: 60px;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

.ui.single.line.table.line-items-table{
  white-space: normal !important;
}


@media only screen and (max-width: 769px) {
  div.line-items-table-container {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
  }

}
