.review-info-box {
  box-sizing: border-box;
  width: 75%;
  max-width: 576px;
  margin: 60px auto;
}

.review-info-content-box {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 12px 24px -6px rgba(9, 30, 66, 0.25);
}

div.review-info-content-title {
  margin: 40px;
  margin-bottom: 0;
  color: #071631;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

div.review-info-content-subtitle {
  margin: 20px 40px;
  color: #071631;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.two-column-container {
  display: flex;
  width: 100%;
  margin-left: auto;
  padding-left: 40px;
  gap: 20px;
  margin-bottom: 30px;
}

.two-column-container div.input-field {
  width: 230px;
}

div.addres-field input.ezinput {
  width: 76%;
}

div.addres-field {
  width: 630px;
}

.address-line2-field {
  width: 192px;
}

.react-switch {
  margin-left: 4px;
  margin-top: 20px;
}

.reciprocation-field {
  margin-top: 0px;
  display: flex;
}

.reciprocation-label {
  display: block;
  margin-top: -30px;
  margin-left: 65px;
  color: #172b4d;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
}

@media (max-width: 767px) {
  .review-info-box {
    margin: 10px auto;
    box-sizing: border-box;
    width: auto;
  }

  .review-info-content-box {
    display: flex;
    flex-direction: column;
    margin: 30px auto;
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
      0 12px 24px -6px rgba(9, 30, 66, 0.25);
  }

  div.review-info-content-title {
    margin: 20px;
    margin-bottom: 0;
    color: #071631;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
  }

  div.review-info-content-subtitle {
    margin: 15px 30px 20px 20px;
    color: #071631;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .two-column-container {
    margin-left: 0;
    padding-left: 20px;
    display: block;
    width: 93%;
  }

  .address-line2-field {
    width: 100%;
  }

  div.addres-field {
    width: 100%;
  }

  div.addres-field input.ezinput {
    width: 100%;
  }
}
