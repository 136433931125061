.ui.label.category-badge {
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 4px;
  height: max-content;
  width: max-content;
  opacity: 0.75;
  font-size: 11px;
  font-weight: 600;
  background: #f4f5f7;
}
