.cart-error-modal {
  font-size: 18px;
  line-height: 25px;
  color: #071631;
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
}

.cart-error-modal.ui.modal .ui.header {
  width: 100%;
  text-align: center;
  display: flex;
  height: 3em;
  align-items: center;
}

.cart-error-modal.ui.modal .header .content,
.cart-error-modal.ui.modal .header .icon {
  padding-bottom: 25px;
}

.cart-error-modal.ui.modal .header .content {
  margin-top: 10px;
}

.bold-label {
  color: #6b778c !important;
  font-family: Barlow;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
}

.cart-error-text {
  color: #172b4d;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
}

@media only screen and (max-width: 769px) {
  .help-text {
    margin-bottom: 2em;
  }
}
