.main-app-content {
  display: block !important;
}

.social-distance {
  margin-left: 10px;
  font-weight: bold;
}

.search-field {
  width: 95%;
  margin: 10px;
}

.filter-staff-text {
  margin-right: 20px;
}

.search-filters-container {
  margin: 10px;
  margin-top: 15px;
  width: 95%;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.shopping-cart-area {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  width: 675px;
}

.pagination-btn-container {
  margin-left: 15px;
}

div.gave-to-filter
  > button.ui.blue.active.basic.compact.toggle.button.btn-gave-in-the-past {
  padding-top: 50px;
}

.ui.blue.active.basic.toggle.button.btn-gave-in-the-past {
  background-color: #e8e8e8 !important;
}

.search-results-text {
  margin-left: 10px;
  color: #737373;
  font: normal normal normal 14px/16px Lato;
}

.search-area-with-match-display {
  background-color: white;
  width: 100%;
  padding-bottom: 10px;
}

.btn-shopping-cart {
  text-align: center;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #ffffff !important;
}

.badge {
  position: relative;
  top: -10px;
  left: -15px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #2185d0;
  color: white;
}

.pagination-buttons {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.page-x-of-y {
  padding: 10px;
  font: normal normal normal 14px/11px Lato;
}

.btn-next {
  width: 66px;
  height: 30px;
  text-align: center;
  font: normal normal bold 14px/17px Lato;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-left: 10px !important;
}

.btn-previous {
  width: 86px;
  height: 30px;
  text-align: center;
  font: normal normal bold 14px/17px Lato;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 10px !important;
}

.disabled.btn-previous,
.disabled.btn-next {
  background-color: #ececec !important;
  color: #8b8b8b !important;
}

.link-clear-filter {
  cursor: pointer;
  margin-left: 10px;
  color: #2185d0;
}

.search-screen {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin: 0 auto;
  background: #f4f5f7;
}

div.filter-staff .ui.blue.basic.button.btn-filter-staff {
  padding-right: 0;
  padding-left: 7px;
}

div.filter-staff .ui.blue.basic.button.active.btn-filter-staff {
  padding-right: 0;
  padding-left: 7px;
  background-color: #e8e8e8 !important;
}

div.filters-panel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: max-content;
  margin-top: -8px;
  padding-bottom: 30px;
  margin-left: 10px;
  z-index: 999;
  width: 671px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 4px 8px -2px rgba(9, 30, 66, 0.25);
}

div.filter-labels {
  margin-left: 10px;
}

div.close-filters {
  margin-top: 15px;
  margin-right: 15px;
  height: 30px;
  grid-column: 3;
  justify-self: end;
}

.close-filters-mobile {
  display: none;
}

.shaloch-manos-navigation-bar {
  display: flex;
  justify-content: space-around;
  background-color: #f4f5f7;
  padding-bottom: 25px;
}

.link-action.lonely-cart {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 20px;
}

.next-button-div {
  display: flex;
  justify-content: center;
}

.next-btn-search {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 40px;
  height: 40px;
  width: 300px;
  border-radius: 3px;
  border: 0px;
  background-color: #071631;
  outline: none;
  color: #e5bb34;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  font-family: 'Barlow';
}

.next-btn-search:hover {
  background-color: #e5bb34;
  color: #071631;
}

@media (max-width: 650px) {
  .breadcrumb {
    display: none;
  }

  .shaloch-manos-navigation-bar {
    padding-top: 20px;
  }

  div.people-table {
    min-width: 320px;
    margin-top: 0;
    padding: 0px;
  }

  .search-screen {
    display: block;
  }

  .search-filters-container {
    margin-left: auto;
  }

  .filter-staff-text {
    margin: 0px;
    width: 110px;
  }

  .ui.table.people-table {
    margin: auto;
  }

  .name-inside-cell {
    word-break: break-word;
  }

  div.filters-panel {
    display: table-cell;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: -8px;
    padding-bottom: 30px;
    margin-left: 10px;
    z-index: 999;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
      0 4px 8px -2px rgba(9, 30, 66, 0.25);
  }

  .ui.checkbox {
    min-width: 24px;
    min-height: 24px;
    font-size: 1.2rem;
    font-family: Lato;
  }

  .close-filters-mobile {
    display: block;
    padding: 10px;
    float: right;
  }

  .close-filters {
    display: none;
  }

  .pagination-buttons {
    margin-bottom: 10px;
  }
}

.add-past-recipients-btn {
  cursor: pointer;
  margin-top: 20px;
  /* margin-bottom: 40px; */
  padding-left: 10px;
  height: 40px;
  /* width: 300px; */
  border-radius: 3px;
  border: 0px;
  background-color: #071631;
  outline: none;
  color: #e5bb34;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  font-family: 'Barlow';
}

.add-past-recipients-btn:hover {
  background-color: #e5bb34;
  color: #071631;
}
