input.react-autosuggest__input {
  box-sizing: border-box;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;
  padding-left: 10px;
  width: 100%;
  line-height: 40px;
  font-family: "Barlow";
  font-size: 16px;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  border: 1px solid #d8d8d8;
  margin-top: -3px;
  border-radius: 2px;
  height: auto;
  background-color: white;
  width: 100%;
}

.react-autosuggest__suggestion {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -40px;
  padding-left: 20px;
  font-family: "Barlow";
  font-size: 16px;
  cursor: pointer;
}

/* .react-autosuggest__suggestion:hover,
.react-autosuggest__suggestion:active, */
.react-autosuggest__suggestion--highlighted {
  background: #d8d8d8;
}
