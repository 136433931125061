@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.App {
  margin: 20px 400px;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Barlow' !important;
}

.password-input,
.email-input {
  width: 300px;
  margin: 10px;
}

.btn-login {
  height: max-content;
}

.login-error {
  margin-left: 15px;
  margin-top: 5px;
}

.verifying {
  margin-left: 15px;
  font-size: medium;
  margin-top: 5px;
}

.login-btn-section {
  margin-top: 20px;
  margin-left: 10px;
  display: flex;
  width: 600px;
  align-content: center;
}

.main-app-content {
  flex-grow: 1;
  display: flex;
  background-color: #f4f5f7;
}
