.pekelach-page {
  display: grid;
  grid-template-columns: repeat(16, [col-start] 1fr);
  gap: 10px;
  margin-bottom: 40px;
  max-width: 1400px;
  margin: 0px auto 30px auto;
}

.pekelach-sold-out {
  font-size: 2em;
  color: blueviolet;
}

.progress-nav {
  width: 400px;
  grid-column: col-start 7 / span 4;
}

.pekelah-grid {
  grid-column: col-start 2 / span 14;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 15px;
}

.nav-btns {
  margin-top: 20px;
  grid-column: col-start 12 / span 4;
  margin-left: auto;
}

.nav-btns .link-action {
  color: #5383ec;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.next-btn {
  cursor: pointer;
  margin: 20px auto 30px;
  grid-column: col-start 7 / span 4;
  height: 40px;
  width: 300px;
  border-radius: 3px;
  border: 0px;
  background-color: #071631;
  outline: none;
  color: #e5bb34;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  font-family: 'Barlow';
}

.next-btn:hover {
  background-color: #e5bb34;
  color: #071631;
}

@media (max-width: 1200px) {
  .progress-nav {
    display: none;
  }

  .nav-btns {
    margin-top: 20px;
    grid-column: col-start 4 / span 12;
    margin-left: auto;
  }

  .next-btn {
    grid-column: col-start 3 / span 12;
  }
}

@media (max-width: 767px) {
  .next-btn {
    grid-column: col-start 1 / span 16;
  }

  .nav-btns {
    grid-column: col-start 1 / span 16;
  }
}
