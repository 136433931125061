.reciprocity {
  width: 50%;
  max-width: 430px;
  min-width: 270px;
  height: auto;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin: 20px 20px 20px 10px;
}

.reciprocity-title {
  height: 24px;
  color: #172b4d;
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 30px;
  margin-left: 30px;
}

.reciprocity-text {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  height: auto;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.reciprocity-divider {
  height: 0px;
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 30px;
}

.reciprocity-switch {
  margin-left: 30px;
  margin-top: 0px;
  margin-bottom: 30px;
  height: auto;
  display: flex;
  align-items: center;
}

.reciprocity-switch span {
  margin-left: 15px;
  height: 20px;
  color: #172b4d;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

@media only screen and (max-width: 769px) {
  .reciprocity {
    margin: 10px;
    width:auto;
  }
}
