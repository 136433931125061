.ezfooter {
  background-color: #071631;
  display: flex;
}

.logo-with-text {
  margin: 0 auto;
  width: 243px;
  height: 76px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-logo-text {
  height: 32px;
  width: 152px;
  color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.call-for-help {
  padding: 20px;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
