.confirmation-page {
  background: url(../../images/confirmation_lattice.svg);
  width: 100%;
  margin: 0;
}

.confirmation-order-content {
  display: flex;
  flex-direction: column;
  margin: 7% auto;
  width: 50%;
  max-width: 700px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 20px 32px -8px rgba(9, 30, 66, 0.25);
}

.confirmation-order-content .top {
  height: 56px;
  background: url(../../images/confirmation_order_lattice.svg);
}

.confirmation-order-content .main-text {
  margin: 40px 40px auto;
  text-align: center;
  color: #071631;
  font-family: Barlow;
  font-size: 40px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}

.confirmation-order-content .second-line {
  margin: 40px 40px auto;
  color: #071631;
  font-family: 'Barlow Condensed';
  font-size: 24px;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
}

.pickup-msg-confirmation {
  margin: 40px;
  color: #071631;
  font-family: Barlow;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

@media only screen and (max-width: 769px) {
  .confirmation-order-content {
    width: 100%;
  }

  .confirmation-order-content .main-text {
    margin: 40px 0px;
  }

  .confirmation-order-content .second-line {
    margin: 20px 10px;
  }

  .pickup-msg-confirmation {
    margin: 40px 15px;
    width: 90%;
    text-align: center;
  }
}
