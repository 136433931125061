.pekelach-ordering-done-page {
  background: url(../../images/confirmation_lattice.svg);
  width: 100%;
  margin: 0;
}

.pekelach-ordering-done-content {
  display: flex;
  flex-direction: column;
  margin: 7% auto;
  width: 40%;
  background-color: #ffffff;
  height: 250px;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 12px 24px -6px rgba(9, 30, 66, 0.25);
}

.pekelach-ordering-done-content .second-line {
  height: 24px;
  width: 351px;
  color: #071631;
  font-family: Lato;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin: 30px auto;
}
.pekelach-ordering-done-content .main-text {
  color: #071631;
  font-family: Lato;
  font-size: 24px;
  letter-spacing: 0;
  text-shadow: 1px 1px 0 #e5bb34;
  text-align: center;
  margin-top: 60px;
}

@media only screen and (max-width: 769px) {
  .pekelach-ordering-done-content {
    width: 100%;
  }
  /*
  .confirmation-order-content .main-text {
    margin: 40px 0px;
  }

  .confirmation-order-content .second-line {
    margin: 20px 10px;
  } */
}
