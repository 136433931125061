.landing-page {
  font-size: 18px;
  line-height: 25px;
  color: #071631;
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  width: 100%;
  margin: auto;
  background: #f4f5f7;
}

h1,
h2,
h3,
h4,
h5 {
  color: #221e1e;
  font-weight: 500 !important;
  font-family: 'Barlow Condensed', sans-serif;
}

.form-control:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.modal-header .close:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.fn {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 500;
  position: relative;
  top: 1px;
  font-size: 41px;
}

.btn1 {
  font-family: 'Barlow Condensed', sans-serif;
  background: #e5bb34 !important;
  color: #071631 !important;
  font-weight: 500;
  font-size: 22px;
  text-transform: uppercase;
  padding: 4px 10px !important;
  margin-right: 15px;
  margin-top: 15px;
}

.btn1:hover {
  background: #071631 !important;
  color: #e5bb34 !important;
  border: 1px solid #e5bb34;
}

.btn2 {
  color: white;
  text-transform: uppercase;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 22px;
  padding: 4px 10px !important;
  margin-top: 15px;
}

.btn2:hover {
  color: #eeeeee !important;
  border: 1px solid #ffffff;
}

.bg-text-1 h1 {
  color: #e5bb34;
  text-transform: uppercase;
  font-size: 58px;
  margin-bottom: 50px;
}

.bg-text-1 h5 {
  color: #e5bb34;
  text-transform: uppercase;
  font-size: 44px;
  margin-bottom: 50px;
}

.cover-bg-0 {
  padding: 110px 0px 100px 0px;
  background: url('../../images/landing/bg1.svg');
}

.cover-bg-1 {
  padding: 110px 0px 100px 0px;
  background: url('../../images/landing/bg2.svg');
}

.cover-bg-2 {
  padding: 70px 0px 0px 0px !important;
  background: url('../../images/landing/bg1.svg');
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 40px;
  position: relative;
  color: #222222;
  font-family: 'Barlow', sans-serif !important;
  font-style: italic;
  font-weight: 600 !important;
}

.section-title div.ordering-done {
  color: #071631;
  font-family: Varela;
  font-size: 60px;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
  text-shadow: -2px 2px 0 #e5bb34;
}

.section-title h2::before,
.section-title h2::after {
  content: '';
  width: 150px;
  height: 2px;
  background: #071631;
  display: inline-block;
}

.section-title h2::before {
  margin: 0 25px 10px 0;
}

.section-title h2::after {
  margin: 0 0 10px 34px;
}

.btn3 {
  font-weight: 600 !important;
  letter-spacing: 4px;
  padding: 14px 31px !important;
  border: 1px solid #071631 !important;
  text-transform: uppercase;
  margin-top: 30px;
  border-radius: 0 !important;
  font-size: 16px !important;
}

.btn3:hover {
  background: rgba(7, 22, 49, 0.3);
}

.bg-text-1 p {
  margin-top: 20px;
}

.bg-text-1 p a {
  color: #5383ec;
  font-family: 'Barlow Condensed', sans-serif;
}

.text {
  font-size: 18px;
}

.section-title-2 {
  text-align: center;
  padding-bottom: 30px;
}

.section-title-2 h2 {
  font-size: 50px;
  color: #e5bb34;
  position: relative;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600 !important;
}

.section-title-2 h2::before,
.section-title-2 h2::after {
  content: '';
  width: 250px;
  height: 2px;
  background: #e5bb34;
  display: inline-block;
  position: relative;
  top: -3px;
}

.section-title-2 h2::before {
  margin: 0 44px 10px 0;
}

.section-title-2 h2::after {
  margin: 0 0 10px 44px;
}

.border-both {
  border-top: 4px solid #e5bb34;
  border-bottom: 4px solid #e5bb34;
}

.border-top-1 {
  border-top: 4px solid #e5bb34;
}

.box1 {
  background: #e9e5d8;
  padding: 20px;
  margin-bottom: 20px;
}

.box1 h2 {
  background: #e5bb34;
  font-weight: 600 !important;
  display: inline-block;
  font-size: 23px;
  padding: 3px 20px;
  margin-left: -20px;
  text-transform: uppercase;
}

.box1 p {
  margin-bottom: 0px;
  font-size: 20px;
  margin-top: 7px;
}

.section-title-3 {
  text-align: center;
  padding-bottom: 30px;
}

.section-title-3 h2 {
  font-size: 50px;
  color: #071631;
  position: relative;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600 !important;
}

.section-title-3 h2::before,
.section-title-3 h2::after {
  content: '';
  width: 250px;
  height: 2px;
  background: #071631;
  display: inline-block;
  position: relative;
  top: -3px;
}

.section-title-3 h2::before {
  margin: 0 44px 10px 0;
}

.section-title-3 h2::after {
  margin: 0 0 10px 44px;
}

.sect-3 {
  border-bottom: 4px solid #e5bb34;
  padding: 100px 0px;
}

.box2 {
  background: #071631;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 20px 32px -8px rgba(9, 30, 66, 0.25);
  margin-bottom: 20px;
}

.box2 h4 {
  color: #e9e5d8;
  font-weight: 600 !important;
  font-size: 14px;
  margin-bottom: 0px !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.box2 h2 {
  font-size: 24px;
  color: #e5bb34;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.box2 p {
  font-size: 18px;
  color: #e5bb34;
  line-height: 22px;
  font-family: 'Barlow Condensed', sans-serif;
  font-style: italic;
  margin-top: 12px;
  margin-bottom: 0px;
}

.box2 span {
  background: #e5bb34;
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  top: 0px;
  min-width: 40px;
  font-size: 20px;
  min-height: 40px;
}

@media (max-width: 767px) {
  .btn1 {
    margin-right: 1px !important;
    line-height: 24px;
    font-size: 20px !important;
    margin-top: 20px;
    padding: 4px !important;
  }

  .btn2 {
    font-size: 20px !important;
    margin-top: 16px;
  }

  header {
    height: 50px;
  }

  header img {
    width: 28px;
    left: -10px;
    top: -4px;
  }

  header span {
    font-size: 24px;
    margin-left: -2px;
    padding-top: 2px;
  }

  .bg-text-1 h1 {
    font-size: 38px;
    margin-bottom: 30px;
  }

  .cover-bg-0,
  .cover-bg-1 {
    padding: 67px 0px 48px 0px;
  }

  .section-title h2::before,
  .section-title h2::after {
    display: none;
  }

  .section-title h2 {
    font-size: 35px;
  }

  .section-title-2 h2 {
    font-size: 37px;
  }

  .bg-text-1 h5 {
    font-size: 39px;
  }

  .sect-3 {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .section-title-2 h2::before,
  .section-title-2 h2::after {
    display: none;
  }

  .section-title-3 h2 {
    font-size: 37px;
  }

  .section-title-3 h2::before,
  .section-title-3 h2::after {
    display: none;
  }

  .fn {
    font-size: 24px;
    top: 0px !important;
  }

  .mobile-header {
    display: flex;
    top: -19px;
    justify-content: flex-end;
  }
}

.have-questions {
  color: #071631;
  font-family: 'Barlow Condensed';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .section-title h2::before,
  .section-title h2::after {
    display: none;
  }

  .section-title-2 h2::before,
  .section-title-2 h2::after {
    display: none;
  }

  .section-title-3 h2::before,
  .section-title-3 h2::after {
    display: none;
  }
}
