.empty-cart {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-cart-text {
  margin-top: 20px;
  height: 38px;
  color: #7d8693;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.add-stuff-btn {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 32px;
  width: 124px;
  border-radius: 3px;
  background-color: #e5bb34;
  border: #e5bb34;
  outline: none;
  height: 32px;
  color: #071631;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
