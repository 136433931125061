.review-cart-page {
  background-color: #f4f5f7;
  font-family: 'Lato';
  display: grid;
  grid-template-columns: 11fr 5fr;
}

@media only screen and (max-width: 1000px) {
  .review-cart-page {
    display:block;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 425px) {
  .review-cart-page {
    display: block;
  }
}
