.quick-order-summary {
  display: none;
}


@media only screen and (max-width: 769px) {
  .quick-order-summary {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
  }

  .quick-order-summary-title{
    font-family: "Barlow Condensed";
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
  }

  .quick-total {
    font-family: "Lato";
    font-weight: 500;
    font-size: 14px;
    padding-top: 10px;
  }

  .quick-checkout {
    padding: 10px 30px;
    border-radius: 3px;
    margin: 10px auto auto 0;
    background-color: #071631;
    cursor: pointer;
    color: #e5bb34;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    outline: none;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quick-checkout:hover {
    cursor: pointer;
    background-color: #e5bb34;
    color: #071631;
  }

  .quick-checkout:disabled {
    background-color: #f7f7f7;
    color: #d8d8d8;
    cursor: auto;
  }

  .quick-pekelach-total {
    color: #7D8693;
  }

  .quick-manos-total {
    color: #7D8693;
  }
}

@media only screen and (max-width: 425px) {
.quick-checkout {
  margin: 20px auto;
}

.quick-checkout:hover {
  cursor: pointer;
  background-color: #e5bb34;
  color: #071631;
}

.quick-checkout:disabled {
  background-color: #f7f7f7;
  color: #d8d8d8;
  cursor: auto;
}
}
