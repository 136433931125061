.payment-method {
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  background-color: white !important;
}

.payment-content {
  padding-left: 30px;
}

.payment-title {
  margin-top: 30px;
  margin-bottom: 10px;
  height: 28px;
  width: fit-content;
  color: #172b4d;
  font-family: Lato;
  font-size: 20.5px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}

.pay-with-check {
  line-height: 28px;
  margin-bottom: 15px;
}

.pay-with-check input {
  margin: 0.4rem;
  vertical-align: -0.1rem;
}

.payment input.ezinput {
  width: 94%;
  max-width: 400px;
}

.payment {
  margin-bottom: 50px;
}

.card-information-title {
  height: 17px;
  width: 109px;
  color: #6b778c;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

.payment-method-bottom {
  background: url(../../images/checkout_lattice.svg);
  height: 76px;
  width: 100%;
}

@media only screen and (max-width: 769px) {
  .payment-method {
    margin: 10px;
    display: flex;
    flex-direction: column;
    background-color: white !important;
  }
}
